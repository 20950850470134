import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PolicyFormField from './PolicyFormField';
import { titleOptions } from 'shared/const/titleOptions';

const PersonalInformationSection = ({ values, onChange, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <h5>{t('POLICY_DETAILS.personalInformation')}</h5>
      <Grid container spacing={2} mb={4}>
        {PersonalInformationSection.getFields(t).map((field, index) => (
          <PolicyFormField
            key={index}
            field={field}
            value={values[field.name]}
            onChange={onChange}
            error={errors[field.name]}
            errorMessage={errors[field.name]}
          />
        ))}
      </Grid>
    </>
  );
};

PersonalInformationSection.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

PersonalInformationSection.getFields = (t) => [
  { name: 'civility', label: t('POLICY_DETAILS.CUSTOMER.civility'), type: 'dropdown', options: titleOptions, isEditable: true, isRequired: true },
  { name: 'email', label: t('POLICY_DETAILS.CUSTOMER.email'), type: 'email', isEditable: true, isRequired: true },
  { name: 'firstName', label: t('POLICY_DETAILS.CUSTOMER.firstName'), type: 'text', isEditable: true, isRequired: true },
  { name: 'phone', label: t('POLICY_DETAILS.CUSTOMER.phone'), type: 'phone', isEditable: true, isRequired: true },
  { name: 'lastName', label: t('POLICY_DETAILS.CUSTOMER.lastName'), type: 'text', isEditable: true, isRequired: true },
  { name: 'mobilePhone', label: t('POLICY_DETAILS.CUSTOMER.mobilePhone'), type: 'phone', isEditable: true, isRequired: false },
  { name: 'birthDate', label: t('POLICY_DETAILS.CUSTOMER.birthDate'), type: 'date', isEditable: false, isRequired: true },
];

export default PersonalInformationSection; 