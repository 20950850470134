import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PolicyFormInput from './PolicyFormInput';
import PolicyFormDatePicker from './PolicyFormDatePicker';
import PolicyFormSelect from './PolicyFormSelect';
import PolicyFormReadOnly from './PolicyFormReadOnly';

const PolicyFormField = ({ field, value, onChange, error, errorMessage }) => {
  const { name, label, type, isEditable, isRequired, options } = field;

  return (
    <Grid item xs={6} className="field-container">
      <div className={`label-text ${isEditable ? 'editable' : ''}`}>
        {label}{isRequired && <span className="required-field">*</span>}:
      </div>
      
      <div className="text-value">
        {!isEditable ? (
          <PolicyFormReadOnly 
            value={value} 
            type={type} 
          />
        ) : type === 'date' ? (
          <PolicyFormDatePicker 
            name={name}
            label={label}
            value={value}
            onChange={(date) => onChange({ name, value: date })}
            error={error}
            errorMessage={errorMessage}
          />
        ) : type === 'dropdown' ? (
          <PolicyFormSelect 
            name={name}
            label={label}
            value={value}
            onChange={(selectedOption) => onChange({ name, value: selectedOption })}
            options={options}
            error={error}
            errorMessage={errorMessage}
          />
        ) : (
          <PolicyFormInput 
            name={name}
            label={label}
            type={type}
            value={value}
            onChange={(e) => onChange({ name, value: e.target.value })}
            isRequired={isRequired}
            error={error}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </Grid>
  );
};

PolicyFormField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    isEditable: PropTypes.bool,
    isRequired: PropTypes.bool,
    options: PropTypes.array,
  }).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default PolicyFormField; 