import React from 'react';
import PropTypes from 'prop-types';
import SoftDatePicker from 'components/SoftDatePicker';
import ValidatableSuiInput from 'shared/components/ValidatableSuiInput/ValidatableSuiInput';
import { CONFIG_APP } from "../../../../../../shared/const/app.config";

const PolicyFormDatePicker = ({ name, label, value, onChange, error, errorMessage }) => {
  return (
    <SoftDatePicker
      label={label}
      value={value}
      onChange={(date) => onChange(new Date(date))}
      renderInput={(params) => <ValidatableSuiInput fullWidth {...params} />}
      options={{
        dateFormat: CONFIG_APP.DATE_FORMAT_DATEPICKER,
      }}
      className="input"
      error={error}
      errorMessage={errorMessage}
    />
  );
};

PolicyFormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default PolicyFormDatePicker; 