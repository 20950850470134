import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PolicyFormField from './PolicyFormField';

const PolicyInformationSection = ({ values, onChange, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <h5>{t('POLICY_DETAILS.policyInformation')}</h5>
      <Grid container spacing={2} mb={4}>
        {PolicyInformationSection.getFields(t).map((field, index) => (
          <PolicyFormField
            key={index}
            field={field}
            value={values[field.name]}
            onChange={onChange}
            error={errors[field.name]}
            errorMessage={errors[field.name]}
          />
        ))}
      </Grid>
    </>
  );
};

PolicyInformationSection.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

PolicyInformationSection.getFields = (t) => [
  { name: 'brand', label: t('POLICY_DETAILS.POLICY.brand'), type: 'text', isEditable: false, isRequired: false },
  { name: 'product', label: t('POLICY_DETAILS.POLICY.product'), type: 'text', isEditable: false, isRequired: false },
  { name: 'subscriptionDate', label: t('POLICY_DETAILS.POLICY.subscriptionDate'), type: 'date', isEditable: false, isRequired: false },
  { name: 'geographicalZone', label: t('POLICY_DETAILS.POLICY.geographicalZone'), type: 'text', isEditable: false, isRequired: false },
  { name: 'bookingDate', label: t('POLICY_DETAILS.POLICY.bookingDate'), type: 'date', isEditable: false, isRequired: false },
  { name: 'duration', label: t('POLICY_DETAILS.POLICY.duration'), type: 'text', isEditable: false, isRequired: false },
  { name: 'startDate', label: t('POLICY_DETAILS.POLICY.startDate'), type: 'date', isEditable: false, isRequired: true },
  { name: 'endDate', label: t('POLICY_DETAILS.POLICY.endDate'), type: 'date', isEditable: false, isRequired: true },
  { name: 'insuranceAddons', label: t('POLICY_DETAILS.POLICY.insuranceAddons'), type: 'text', isEditable: false, isRequired: false },
  { name: 'insuranceOptions', label: t('POLICY_DETAILS.POLICY.insuranceOptions'), type: 'text', isEditable: false, isRequired: false },
  { name: 'quoteAgent', label: t('POLICY_DETAILS.POLICY.quoteAgent'), type: 'text', isEditable: false, isRequired: false },
  { name: 'affiliateOrAggregateId', label: t('POLICY_DETAILS.POLICY.affiliateOrAggregateId'), type: 'text', isEditable: false, isRequired: false },
  { name: 'salesAgent', label: t('POLICY_DETAILS.POLICY.salesAgent'), type: 'text', isEditable: false, isRequired: false },
];

export default PolicyInformationSection; 