import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PolicyFormField from './PolicyFormField';
import { STATES_OPTIONS } from 'layouts/home/customerDetails/customerTab/customerTab.consts';

const AddressInformationSection = ({ values, onChange, errors }) => {
  const { t } = useTranslation();
  
  return (
    <>
      <h5>{t('POLICY_DETAILS.addressInformation')}</h5>
      <Grid container spacing={2} mb={4}>
        {AddressInformationSection.getFields(t).map((field, index) => (
          <PolicyFormField
            key={index}
            field={field}
            value={values[field.name]}
            onChange={onChange}
            error={errors[field.name]}
            errorMessage={errors[field.name]}
          />
        ))}
      </Grid>
    </>
  );
};

AddressInformationSection.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

AddressInformationSection.getFields = (t) => [
  { name: 'address1', label: t('POLICY_DETAILS.CUSTOMER.address1'), type: 'address', isEditable: true, isRequired: true },
  { name: 'city', label: t('POLICY_DETAILS.CUSTOMER.city'), type: 'text', isEditable: true, isRequired: true },
  { name: 'address2', label: t('POLICY_DETAILS.CUSTOMER.address2'), type: 'address', isEditable: true, isRequired: false },
  { name: 'state', label: t('POLICY_DETAILS.CUSTOMER.state'), type: 'dropdown', options: STATES_OPTIONS, isEditable: true, isRequired: true },
  { name: 'postalCode', label: t('POLICY_DETAILS.CUSTOMER.postalCode'), type: 'code', isEditable: true, isRequired: true },
  { name: 'country', label: t('POLICY_DETAILS.CUSTOMER.country'), type: 'text', isEditable: false, isRequired: false },
];

export default AddressInformationSection; 