import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import './policyTab.scss';
import Grid from '@mui/material/Grid';
import {format} from 'date-fns';
import {splitAndFilterString} from "../../../../shared/helpers/string.helper";
import SuiButton from '../../../../components/SoftButton';
import EditPolicyForm from './editPolicyForm/editPolicyForm';

const PolicyTab = ({ policyDetails, persistentQuoteDetails, onPolicyDetailsUpdate }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [isPolicyExpired, setIsPolicyExpired] = useState(false);

  useEffect(() => {
    if (policyDetails) {
      const initialValues = {
        subscriptionDate: format(new Date(policyDetails.policy.subscriptionDate), 'dd/MM/yyyy'),
        bookingDate: format(new Date(policyDetails.insuranceData.bookingDate), 'dd/MM/yyyy'),
        startDate: format(new Date(policyDetails.insuranceData.startDate), 'dd/MM/yyyy'),
        endDate: format(new Date(policyDetails.insuranceData.endDate), 'dd/MM/yyyy'),
        duration: `${policyDetails.insuranceData.duration} day(s)`,
        geographicalZone: policyDetails.policy.genericData.geographicalZone,
        insuranceAddons: splitAndFilterString(policyDetails.policy.genericData.insuranceAddons),
        insuranceOptions: splitAndFilterString(policyDetails.policy.genericData.insuranceOptions),
        //amountToInsure: policyDetails.insuranceData.amountToInsure > 0 ? `${policyDetails.insuranceData.amountToInsure} ${policyDetails.policy.price.currency}` : undefined,
        brand: policyDetails.policy.genericData.brand,
        product: policyDetails.product.productCode,
        quoteAgent: persistentQuoteDetails?.quote.genericData.agentSales,
        salesAgent: policyDetails.policy.genericData.agentSales,
        affiliateOrAggregateId: policyDetails.policy.genericData.partnerCode,
      };
      setEditedValues(initialValues);
      
      // Check if policy is expired (end date is in the past)
      const endDate = new Date(policyDetails.insuranceData.endDate);
      const today = new Date();
      setIsPolicyExpired(endDate < today);
    }
  }, [policyDetails, persistentQuoteDetails]);


  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    onPolicyDetailsUpdate();
    setEditing(false);
  };

  const handleCancelClick = () => {
    setEditing(false);
  };


  // Fields based on policyDetails
  const fields = [
    { name: 'brand', label: t('POLICY_DETAILS.POLICY.brand'), isEditable: false },
    { name: 'product', label: t('POLICY_DETAILS.POLICY.product'), isEditable: false },
    { name: 'subscriptionDate', label: t('POLICY_DETAILS.POLICY.subscriptionDate'), isEditable: false },
    { name: 'geographicalZone', label: t('POLICY_DETAILS.POLICY.geographicalZone'), isEditable: false },
    { name: 'bookingDate', label: t('POLICY_DETAILS.POLICY.bookingDate'), isEditable: false },
    { name: 'duration', label: t('POLICY_DETAILS.POLICY.duration'), isEditable: false },
    { name: 'startDate', label: t('POLICY_DETAILS.POLICY.startDate'), type: 'date', isEditable: true },
    { name: 'endDate', label: t('POLICY_DETAILS.POLICY.endDate'), type: 'date', isEditable: true },
    //{ name: 'amountToInsure', label: t('POLICY_DETAILS.POLICY.amountToInsure'), isEditable: false },
    { name: 'insuranceAddons', label: t('POLICY_DETAILS.POLICY.insuranceAddons'), isEditable: false },
    { name: 'insuranceOptions', label: t('POLICY_DETAILS.POLICY.insuranceOptions'), isEditable: false },
    { name: 'quoteAgent', label: t('POLICY_DETAILS.POLICY.quoteAgent'), isEditable: false },
    { name: 'affiliateOrAggregateId', label: t('POLICY_DETAILS.POLICY.affiliateOrAggregateId'), isEditable: false },
    { name: 'salesAgent', label: t('POLICY_DETAILS.POLICY.salesAgent'), isEditable: false },
  ];

  if (editing) {
    return <EditPolicyForm 
      policyDetails={policyDetails} 
      onPolicyDetailsUpdate={handleSaveClick} 
      onCancel={handleCancelClick} 
    />;
  }

  return (
    <div className="policyTab">
      <div className="header">
      <h4>{t('POLICY_DETAILS.tabPolicy')}</h4>

        <div>
          {!isPolicyExpired && (
            <SuiButton
              size="small"
              variant="gradient"
              color="info"
              className="button"
              onClick={handleEditClick}
            >
              {t('Edit')}
            </SuiButton>
          )}
        </div>
      </div>
      <Grid container spacing={2}>
        {fields.map((field, index) => (
          <Grid item xs={6} key={index} className="field-container">
            <div className={`label-text`}>
              {field.label}:
            </div>
            <div className="text-value">{editedValues[field.name]}</div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

PolicyTab.propTypes = {
  policyDetails: PropTypes.object,
  persistentQuoteDetails: PropTypes.object,
  onPolicyDetailsUpdate: PropTypes.func,
};

export default PolicyTab;
