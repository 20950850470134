import SuiInput from "../../../../components/SoftInput";
import styles from "../policies.module.scss";
import SoftButton from "../../../../components/SoftButton";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const PoliciesSearch = (props) => {
    const { fetchData } = props;
    const {t} = useTranslation();
    const [filters, setFilters] = useState({
        policyId: "",
        legacyPolicyId: "",
        quoteId: "",
        transactionId: "",
        phoneNumber: "",
        product: "",
        catalog: "",
        productName: "",
        brand: "",
        name: "",
        postcode: "",
    });

    const handleChange = (field) => (event) => {
        setFilters((prev) => ({
            ...prev,
            [field]: event.target.value,
        }));
    };

    const handleSearchButtonClick = () => {
        fetchData(filters);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchButtonClick();
        }
    };

    return (
        <div className={styles.filtersGrid}>
            <div>
                <label>{t("POLICIES.policyId")}</label>
                <SuiInput
                    placeholder="1234567890"
                    onKeyPress={handleKeyPress}
                    value={filters.policyId}
                    onChange={handleChange("policyId")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.legacyPolicyId")}</label>
                <SuiInput
                    placeholder="MS/PL/000001"
                    onKeyPress={handleKeyPress}
                    value={filters.legacyPolicyId}
                    onChange={handleChange("legacyPolicyId")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.quoteId")}</label>
                <SuiInput
                    placeholder="D123456"
                    onKeyPress={handleKeyPress}
                    value={filters.quoteId}
                    onChange={handleChange("quoteId")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.transactionId")}</label>
                <SuiInput
                    placeholder="a1b2c3d4"
                    onKeyPress={handleKeyPress}
                    value={filters.transactionId}
                    onChange={handleChange("transactionId")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.phoneNumber")}</label>
                <SuiInput
                    placeholder="+48123456789"
                    onKeyPress={handleKeyPress}
                    value={filters.phoneNumber}
                    onChange={handleChange("phoneNumber")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.product")}</label>
                <SuiInput
                    placeholder="ABCD1234"
                    onKeyPress={handleKeyPress}
                    value={filters.product}
                    onChange={handleChange("product")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.productName")}</label>
                <SuiInput
                    placeholder="Basic Insurance"
                    onKeyPress={handleKeyPress}
                    value={filters.productName}
                    onChange={handleChange("productName")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.catalog")}</label>
                <SuiInput
                    placeholder="2024.1"
                    onKeyPress={handleKeyPress}
                    value={filters.catalog}
                    onChange={handleChange("catalog")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.brand")}</label>
                <SuiInput
                    placeholder="TICK / IGO"
                    onKeyPress={handleKeyPress}
                    value={filters.brand}
                    onChange={handleChange("brand")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.nameSurname")}</label>
                <SuiInput
                    placeholder="John Doe"
                    onKeyPress={handleKeyPress}
                    value={filters.name}
                    onChange={handleChange("name")}
                    variant="outlined"
                />
            </div>
            <div>
                <label>{t("POLICIES.postcode")}</label>
                <SuiInput
                    placeholder="12345"
                    onKeyPress={handleKeyPress}
                    value={filters.postcode}
                    onChange={handleChange("postcode")}
                    variant="outlined"
                />
            </div>
            <SoftButton
                variant="outlined"
                color="info"
                onClick={handleSearchButtonClick}
                style={{alignSelf: "end", height: "fit-content"}}
            >
                {t("POLICIES.searchButton")}
            </SoftButton>
        </div>
    )
}

PoliciesSearch.propTypes = {
    fetchData: PropTypes.func.isRequired,
};

PoliciesSearch.defaultProps = {};

export default PoliciesSearch;