import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const PolicyFormReadOnly = ({ value, type }) => {
  const { t } = useTranslation();
  if (value === null || value === undefined) {
    return <span>{t('No data')}</span>;
  }

  if (type === 'date' && value instanceof Date) {
    return <span>{format(value, 'dd/MM/yyyy')}</span>;
  }

  return <span>{value}</span>;
};

PolicyFormReadOnly.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
};

export default PolicyFormReadOnly; 