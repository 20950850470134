import React from 'react';
import PropTypes from 'prop-types';
import ValidatableSuiInput from 'shared/components/ValidatableSuiInput/ValidatableSuiInput';

const MAX_LENGTH = 100;

const PolicyFormInput = ({ name, label, type, value, onChange, isRequired, error, errorMessage }) => {
  return (
    <ValidatableSuiInput
      fullWidth
      name={name}
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      required={isRequired}
      inputProps={{ maxLength: MAX_LENGTH }}
      className="input"
      error={error}
      errorMessage={errorMessage}
    />
  );
};

PolicyFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default PolicyFormInput; 