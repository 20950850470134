import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ValidatableSuiInput from 'shared/components/ValidatableSuiInput/ValidatableSuiInput';
import { differenceInYears } from 'date-fns';

const TravelersSection = ({ travelers, onChange, errors, policySubscriptionDate }) => {
  const { t } = useTranslation();

  const calculateAge = (birthdate, subscriptionDate) => {
    if (!birthdate || !subscriptionDate) {
      return '-';
    }
    return differenceInYears(new Date(subscriptionDate), new Date(birthdate));
  };

  const renderTravelerInputField = (label, value, key, index) => (
    <div className="field-container">
      <div className="label-text">
        {label}:
      </div>
      <div className="text-value">
        <ValidatableSuiInput
          fullWidth
          value={value}
          required
          inputProps={{ maxLength: 100 }}
          onChange={(e) => onChange(index, key, e.target.value)}
          errorMessage={errors[index]?.[key]}
          error={!!errors[index]?.[key]}
        />
      </div>
    </div>
  );

  const renderTravelerTextField = (label, value) => (
    <div className="field-container">
      <div className="label-text">
        {label}:
      </div>
      <div className="text-value">
        {value}
      </div>
    </div>
  );

  return (
    <>
      <h5>{t('POLICY_DETAILS.tabTravelers')}</h5>
      <Grid container spacing={2} mb={4}>
        {travelers.map((beneficiary, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <div className="traveler-card">
              <div className="traveler-header">
                <strong>{t('Traveler')} {index + 1}</strong>
              </div>
              {renderTravelerInputField(t('POLICY_DETAILS.TRAVELERS.firstName'), beneficiary.firstName, 'firstName', index)}
              {renderTravelerInputField(t('POLICY_DETAILS.TRAVELERS.lastName'), beneficiary.lastName, 'lastName', index)}
              {renderTravelerTextField(t('POLICY_DETAILS.TRAVELERS.age'), beneficiary.birthDate ?
                calculateAge(beneficiary.birthDate, policySubscriptionDate) : beneficiary.value8)}
              {renderTravelerTextField(t('POLICY_DETAILS.TRAVELERS.medicalInfo'), beneficiary?.medicalInfoExists ? t('COMMON.yes') : t('COMMON.no'))}
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

TravelersSection.propTypes = {
  travelers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  policySubscriptionDate: PropTypes.string,
};

export default TravelersSection; 