import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SuiButton from '../../../../../components/SoftButton';
import './editPolicyForm.scss';
import { Divider, Grid } from '@mui/material';
import { format } from 'date-fns';
import useUpdatePolicyDetails from '../../useUpdatePolicyDetails';
import { NotificationContext } from 'shared/context/notificationContext';
import { reportError } from 'shared/services/raygunService';
import { emailValidationRegex, phoneNumberValidationRegex, allowedChars } from 'shared/const/regexps';
import { optionalPhoneNumberValidationRegex } from 'shared/const/regexps';
import { splitAndFilterString } from 'shared/helpers/string.helper';
import EditPolicyFormPropTypes from './editPolicyForm.propTypes';
import { transformPolicyData } from './utils/transformPolicyData';

// Import section components
import PolicyInformationSection from './components/PolicyInformationSection';
import PersonalInformationSection from './components/PersonalInformationSection';
import AddressInformationSection from './components/AddressInformationSection';
import TravelersSection from './components/TravelersSection';

const MAX_LENGTH = 100;

const EditPolicyForm = ({ policyDetails, onPolicyDetailsUpdate, onCancel }) => {
  const { t } = useTranslation();
  const [editedValues, setEditedValues] = useState({});
  const { updatePolicyDetails } = useUpdatePolicyDetails();
  const [fieldErrors, setFieldErrors] = useState({});
  const [travelerErrors, setTravelerErrors] = useState([]);
  const [editedTravelers, setEditedTravelers] = useState([]);
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (policyDetails) {
      const initialValues = {
        // Policy information
        brand: policyDetails.policy.genericData.brand,
        product: policyDetails.product.productCode,
        subscriptionDate: new Date(policyDetails.policy.subscriptionDate),
        geographicalZone: policyDetails.policy.genericData.geographicalZone,
        bookingDate: new Date(policyDetails.insuranceData.bookingDate),
        duration: `${policyDetails.insuranceData.duration} day(s)`,
        startDate: new Date(policyDetails.insuranceData.startDate),
        endDate: new Date(policyDetails.insuranceData.endDate),
        insuranceAddons: splitAndFilterString(policyDetails.policy.genericData.insuranceAddons),
        insuranceOptions: splitAndFilterString(policyDetails.policy.genericData.insuranceOptions),
        quoteAgent: policyDetails.policy.genericData.agentSales,
        affiliateOrAggregateId: policyDetails.policy.genericData.partnerCode,
        salesAgent: policyDetails.policy.genericData.agentSales,
        
        // Customer information
        civility: policyDetails.subscriber?.civility,
        firstName: policyDetails.subscriber?.firstName,
        lastName: policyDetails.subscriber?.lastName,
        birthDate: policyDetails.subscriber?.birthDate ? new Date(policyDetails.subscriber.birthDate) : null,
        email: policyDetails.subscriber?.email,
        phone: policyDetails.subscriber?.phone,
        mobilePhone: policyDetails.subscriber?.mobilePhone,
        address1: policyDetails.subscriber?.address1,
        address2: policyDetails.subscriber?.address2,
        city: policyDetails.subscriber?.city,
        postalCode: policyDetails.subscriber?.postalCode,
        country: policyDetails.subscriber?.country,
        state: policyDetails.policy.genericData?.state,
        preferredLanguage: policyDetails.subscriber?.preferredLanguage,
      };
      setEditedValues(initialValues);
      
      // Initialize travelers/beneficiaries
      if (policyDetails.beneficiaries) {
        setEditedTravelers([...policyDetails.beneficiaries]);
      }
    }
  }, [policyDetails]);

  const handleInputChange = ({ name, value }) => {
    setEditedValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTravelerChange = (index, field, value) => {
    const updatedTravelers = [...editedTravelers];
    updatedTravelers[index] = {
      ...updatedTravelers[index],
      [field]: value,
    };
    setEditedTravelers(updatedTravelers);
  };

  const validateForm = () => {
    const errors = {};
    const travelerValidationErrors = [];

    // Validate policy fields
    PolicyInformationSection.getFields(t).forEach((field) => {
      if (field.isRequired && !editedValues[field.name]) {
        errors[field.name] = t('VALIDATION.required');
      }
    });

    // Validate personal information fields
    PersonalInformationSection.getFields(t).forEach((field) => {
      if (field.isRequired && !editedValues[field.name]) {
        errors[field.name] = t('VALIDATION.required');
      } else if (field.name === 'email' && editedValues.email && !emailValidationRegex.test(editedValues.email)) {
        errors.email = t('VALIDATION.invalidEmail');
      } else if (field.name === 'phone' && editedValues.phone && !phoneNumberValidationRegex.test(editedValues.phone)) {
        errors.phone = t('VALIDATION.invalidPhone');
      } else if (field.name === 'mobilePhone' && editedValues.mobilePhone && !optionalPhoneNumberValidationRegex.test(editedValues.mobilePhone)) {
        errors.mobilePhone = t('VALIDATION.invalidPhone');
      }
    });

    // Validate address fields
    AddressInformationSection.getFields(t).forEach((field) => {
      if (field.isRequired && !editedValues[field.name]) {
        errors[field.name] = t('VALIDATION.required');
      }
    });

    // Validate travelers
    editedTravelers.forEach((traveler, index) => {
      const travelerErrors = {};
      if (!traveler.firstName) {
        travelerErrors.firstName = t('VALIDATION.required');
      }
      if (!traveler.lastName) {
        travelerErrors.lastName = t('VALIDATION.required');
      }
      if (!traveler.birthDate) {
        travelerErrors.birthDate = t('VALIDATION.required');
      }
      travelerValidationErrors[index] = travelerErrors;
    });

    setFieldErrors(errors);
    setTravelerErrors(travelerValidationErrors);

    return Object.keys(errors).length === 0 && travelerValidationErrors.every(err => Object.keys(err).length === 0);
  };

  const handleSaveClick = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      // Use the utility function to transform the data
      const updatedPolicyDetails = transformPolicyData(policyDetails, editedValues, editedTravelers);
      
      await updatePolicyDetails(policyDetails.policy.policyNumber, updatedPolicyDetails);
      showNotification(t('POLICY_DETAILS.updateSuccess'), 'success');
      onPolicyDetailsUpdate();
    } catch (error) {
      reportError(error);
      showNotification(t('POLICY_DETAILS.updateError'), 'error');
    }
  };

  return (
    <div className="editPolicyForm">
      <div className="header">
        <h4>{t('POLICY_DETAILS.editPolicy')}</h4>
        <div className="button-container">
          <SuiButton
            size="small"
            variant="gradient"
            color="info"
            className="button"
            onClick={handleSaveClick}
          >
            {t('COMMON.save')}
          </SuiButton>
          <SuiButton
            size="small"
            variant="gradient"
            color="secondary"
            className="button"
            onClick={onCancel}
          >
            {t('COMMON.cancel')}
          </SuiButton>
        </div>
      </div>

      <PolicyInformationSection
        values={editedValues}
        onChange={handleInputChange}
        errors={fieldErrors}
      />

      <Divider className="section-divider" />

      <PersonalInformationSection
        values={editedValues}
        onChange={handleInputChange}
        errors={fieldErrors}
      />

      <Divider className="section-divider" />

      <AddressInformationSection
        values={editedValues}
        onChange={handleInputChange}
        errors={fieldErrors}
      />

      <Divider className="section-divider" />

      <TravelersSection
        travelers={editedTravelers}
        policySubscriptionDate={editedValues.subscriptionDate}
        onChange={handleTravelerChange}
        errors={travelerErrors}
      />
    </div>
  );
};

EditPolicyForm.propTypes = EditPolicyFormPropTypes;

export default EditPolicyForm;
