import PropTypes from 'prop-types';

const EditPolicyFormPropTypes = {
  policyDetails: PropTypes.shape({
    policy: PropTypes.shape({
      policyNumber: PropTypes.string,
      status: PropTypes.shape({
        label: PropTypes.string,
      }),
      subscriptionDate: PropTypes.string,
      genericData: PropTypes.shape({
        state: PropTypes.string,
        brand: PropTypes.string,
        geographicalZone: PropTypes.string,
        insuranceAddons: PropTypes.string,
        insuranceOptions: PropTypes.string,
        agentSales: PropTypes.string,
        partnerCode: PropTypes.string,
      }),
    }),
    insuranceData: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      premium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      productName: PropTypes.string,
      planName: PropTypes.string,
      paymentFrequency: PropTypes.string,
      bookingDate: PropTypes.string,
      duration: PropTypes.number,
    }),
    product: PropTypes.shape({
      productCode: PropTypes.string,
    }),
    subscriber: PropTypes.shape({
      civility: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      birthDate: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      mobilePhone: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      country: PropTypes.string,
      preferredLanguage: PropTypes.string,
    }),
    beneficiaries: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        birthDate: PropTypes.string,
        countryCode: PropTypes.string,
        index: PropTypes.string,
        companionId: PropTypes.number,
        subscriber: PropTypes.bool,
        medicalInfoExists: PropTypes.bool,
      })
    ),
  }),
  onPolicyDetailsUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditPolicyFormPropTypes; 