import React, { useState, useEffect, useContext, memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SuiButton from '../../../../components/SoftButton';
import './customerTab.scss';
import { Divider, Grid } from '@mui/material';
import ValidatableSuiInput from 'shared/components/ValidatableSuiInput/ValidatableSuiInput';
import { format } from 'date-fns';
import SoftDatePicker from 'components/SoftDatePicker';
import SoftSelect from 'components/SoftSelect';
import { MAX_LENGTH } from './customerTab.consts';
import useUpdatePolicyDetails from '../useUpdatePolicyDetails';
import { emailValidationRegex, phoneNumberValidationRegex, allowedChars } from 'shared/const/regexps';
import { titleOptions } from 'shared/const/titleOptions';
import { optionalPhoneNumberValidationRegex } from 'shared/const/regexps';
import { NotificationContext } from 'shared/context/notificationContext';
import dayjs from 'dayjs';
import {CONFIG_APP} from "../../../../shared/const/app.config";
import useOptOutOfMarketingConsents from '../useOptOutOfMarketingConsents';
import { reportError } from 'shared/services/raygunService';
import { STATES_OPTIONS } from 'layouts/home/customerDetails/customerTab/customerTab.consts';

const CustomerTab = memo(({ policyDetails, onPolicyDetailsUpdate }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const { updatePolicyDetails } = useUpdatePolicyDetails();
  const [fieldErrors, setFieldErrors] = useState({});
  const { showNotification } = useContext(NotificationContext);
  const { optOutOfMarketingConsents } = useOptOutOfMarketingConsents();

  const personalInformationFields = [
    { name: 'civility', label: t('POLICY_DETAILS.CUSTOMER.civility'), type: 'dropdown', options: titleOptions, isEditable: true, isRequired: true },
    { name: 'email', label: t('POLICY_DETAILS.CUSTOMER.email'), type: 'email', isEditable: true, isRequired: true },
    { name: 'firstName', label: t('POLICY_DETAILS.CUSTOMER.firstName'), type: 'text', isEditable: true, isRequired: true },
    { name: 'phone', label: t('POLICY_DETAILS.CUSTOMER.phone'), type: 'phone', isEditable: true, isRequired: true },
    { name: 'lastName', label: t('POLICY_DETAILS.CUSTOMER.lastName'), type: 'text', isEditable: true, isRequired: true },
    { name: 'mobilePhone', label: t('POLICY_DETAILS.CUSTOMER.mobilePhone'), type: 'phone', isEditable: true, isRequired: false },
    { name: 'birthDate', label: t('POLICY_DETAILS.CUSTOMER.birthDate'), type: 'date', isEditable: false, isRequired: true },
  ];

  const addressFields = [
    { name: 'address1', label: t('POLICY_DETAILS.CUSTOMER.address1'), type: 'address', isEditable: true, isRequired: true },
    { name: 'city', label: t('POLICY_DETAILS.CUSTOMER.city'), type: 'text', isEditable: true, isRequired: true },
    { name: 'address2', label: t('POLICY_DETAILS.CUSTOMER.address2'), type: 'address', isEditable: true, isRequired: false },
    { name: 'state', label: t('POLICY_DETAILS.CUSTOMER.state'), type: 'dropdown', options: STATES_OPTIONS, isEditable: false, isRequired: true },
    { name: 'postalCode', label: t('POLICY_DETAILS.CUSTOMER.postalCode'), type: 'code', isEditable: true, isRequired: true },
    { name: 'country', label: t('POLICY_DETAILS.CUSTOMER.country'), type: 'text', isEditable: false, isRequired: false },
  ];
  useEffect(() => {
    if (policyDetails) {
      const initialValues = {
        civility: policyDetails.subscriber.civility,
        firstName: policyDetails.subscriber.firstName,
        lastName: policyDetails.subscriber.lastName,
        birthDate: new Date(policyDetails.subscriber.birthDate),
        email: policyDetails.subscriber.email,
        phone: policyDetails.subscriber.phone,
        mobilePhone: policyDetails.subscriber.mobilePhone,
        address1: policyDetails.subscriber.address1,
        address2: policyDetails.subscriber.address2,
        city: policyDetails.subscriber.city,
        postalCode: policyDetails.subscriber.postalCode,
        country: policyDetails.subscriber.country,
        state: policyDetails.policy.genericData.state,
        preferredLanguage: policyDetails.subscriber.preferredLanguage,
      };
      setInputValues(initialValues);
      setEditedValues(initialValues);
    }
  }, [policyDetails]);
  const handleEditClick = () => {
    if (policyDetails && !dayjs(policyDetails.insuranceData.endDate).add(6, 'month').isAfter(dayjs(), 'day')) {
      showNotification(t("POLICY_DETAILS.editingAfterEndNotification"), 'error');
      return;
    }
    
    if (policyDetails && dayjs(policyDetails.insuranceData.endDate).isBefore(dayjs(), 'day')) {
      showNotification(t("POLICY_DETAILS.editingExpiredPolicyNotification"), 'error');
      return;
    }
    
    if (policyDetails && policyDetails.policy.status.label.toLowerCase() !== 'cancelled') {
      setEditing(true);
    }
  };

  const handleSaveClick = async () => {
    if (!policyDetails || !validateForm()) {
      return;
    }

    const updatedPolicyDetails = {
      ...policyDetails,
      subscriber: {
        ...policyDetails.subscriber,
        civility: editedValues.civility,
        firstName: editedValues.firstName,
        lastName: editedValues.lastName,
        birthDate: format(
          new Date(
            typeof editedValues.birthDate === 'string' && editedValues.birthDate.includes('/')
              ? editedValues.birthDate.split('/').reverse().join('-')
              : editedValues.birthDate
          ),
            'yyyy-MM-dd'
        ),
        email: editedValues.email,
        phone: editedValues.phone,
        mobilePhone: editedValues.mobilePhone,
        address1: editedValues.address1,
        address2: editedValues.address2,
        city: editedValues.city,
        postalCode: editedValues.postalCode,
        country: editedValues.country,
        preferredLanguage: editedValues.preferredLanguage,
      },
      beneficiaries: policyDetails.beneficiaries.map(beneficiary => {
        if (beneficiary.subscriber) {
          return {
            ...beneficiary,
            firstName: editedValues.firstName,
            lastName: editedValues.lastName,
            birthDate: format(new Date(editedValues.birthDate), 'yyyy-MM-dd'),
          };
        }
        return beneficiary;
      }),
      policy: {
        ...policyDetails.policy,
        genericData: {
          ...policyDetails.policy.genericData,
          state: editedValues.state,
        },
      },
    };
    try {
      await updatePolicyDetails(policyDetails.policy.policyNumber, updatedPolicyDetails);
      onPolicyDetailsUpdate();
    } catch (error) {
      console.error('Error saving policy details:', error);
      reportError(error);
    }

    setInputValues(editedValues);
    setEditing(false);
  };

  const handleCancelClick = () => {
    setEditedValues(inputValues);
    setEditing(false);
    setFieldErrors({});
  };

  const unsubscribeHandler = (type) => {
    optOutOfMarketingConsents(type, policyDetails.bookingDataId);
  };

  const handleInputChange = (fieldName, value, fieldType, isRequired) => {
    let error = null;

    if (isRequired && (value === null || value === '')) {
      error = t('COMMON.error.requiredField');
    } else if (fieldType === 'email') {
      if (!emailValidationRegex.test(value)) {
        error = t('COMMON.error.invalidEmail');
      }
    } else if (fieldName === 'phone') {
      if (!phoneNumberValidationRegex.test(value)) {
        error = t('COMMON.error.invalidPhone');
      }
    } else if (fieldName === 'mobilePhone') {
      if (!optionalPhoneNumberValidationRegex.test(value  || '')) {
        error = t('COMMON.error.invalidPhone');
      }
    } else {
      if ((fieldType === 'text') && allowedChars.test(value)) {
        error = t('COMMON.error.invalidValue');
      } else if (value.length > MAX_LENGTH) {
        error = t('COMMON.error.maxLengthExceeded');
      }
    }

    const newEditedValues = {
      ...editedValues,
      [fieldName]: value.value ? value.value : value,
    };

    setEditedValues(newEditedValues);

    const newFieldErrors = { ...fieldErrors, [fieldName]: error };
    setFieldErrors(newFieldErrors);
  };

  const validateForm = () => {
    const newFieldErrors = {};

    personalInformationFields.concat(addressFields).forEach(field => {
      const { name, type, isRequired } = field;
      const value = editedValues[name];

      let error = null;
      if (isRequired && (value === null || value === '' || value === undefined)) {
        error = t('COMMON.error.requiredField');
      } else if (type === 'email' && !emailValidationRegex.test(value)) {
        error = t('COMMON.error.invalidEmail');
      } else if (name === 'phone' && !phoneNumberValidationRegex.test(value)) {
        error = t('COMMON.error.invalidPhone');
      } else if (name === 'mobilePhone' && !optionalPhoneNumberValidationRegex.test(value || '')) {
        error = t('COMMON.error.invalidPhone');
      } else if ((type === 'text' || !type) && allowedChars.test(value)) {
        error = t('COMMON.error.invalidValue');
      } else if (value && value.length > MAX_LENGTH) {
        error = t('COMMON.error.maxLengthExceeded');
      }

      newFieldErrors[name] = error;
    });

    setFieldErrors(newFieldErrors);

    return Object.values(newFieldErrors).every(error => !error);
  };

  // Check if edit button should be shown
  const shouldShowEditButton = policyDetails && 
    policyDetails.policy.status.label.toLowerCase() !== 'cancelled' && 
    !dayjs(policyDetails.insuranceData.endDate).isBefore(dayjs(), 'day');

  return (
    <div className="customerTab">
      <div className="header">
        <h4>{t('POLICY_DETAILS.tabCustomer')}</h4>
        <Grid sx={{display: 'flex', gap: 2}}>
          <SuiButton
            size="small"
            className="button"
            onClick={() => unsubscribeHandler('email')}
          >
            {t('POLICY_DETAILS.CUSTOMER.marketingOptOut')}
          </SuiButton>
          <SuiButton
            size="small"
            className="button"
            onClick={() => unsubscribeHandler('sms')}
          >
            {t('POLICY_DETAILS.CUSTOMER.marketingSMSOptOut')}
          </SuiButton>
          {!editing && shouldShowEditButton && (
            <SuiButton
              size="small"
              variant="gradient"
              color="info"
              className="button"
              onClick={handleEditClick}
            >
              {t('COMMON.edit')}
            </SuiButton>
          )}
          {editing && (
            <>
              <SuiButton
                size="small"
                variant="gradient"
                color="success"
                className="button"
                onClick={handleSaveClick}
              >
                {t('COMMON.save')}
              </SuiButton>
              <SuiButton
                size="small"
                variant="outlined"
                color="info"
                className="button ml"
                onClick={handleCancelClick}
              >
                {t('COMMON.cancel')}
              </SuiButton>
            </>
          )}
        </Grid>
      </div>

      <Grid container spacing={2} mb={4}>
        {personalInformationFields.map((field, index) => (
          <Grid item xs={6} key={index} className="field-container">
            <div className={`label-text ${field.isEditable && editing ? 'editable' : ''}`}>
              {field.label}{(field.isRequired && editing) && <span className="required-field">*</span>}:
            </div>
            {field.isEditable && editing ? (
              field.type === 'date' ?  field.type === 'dropdown' ? (
                <SoftSelect
                  value={{ value: editedValues[field.name], label: editedValues[field.name] }}
                  onChange={(selectedOption) => handleInputChange(field.name, selectedOption)}
                  options={titleOptions}
                  isDisabled={!editing}
                  className="input"
                />
              ) : (
                <SoftDatePicker
                  label={field.label}
                  value={editedValues[field.name]}
                  onChange={(date) => handleInputChange(field.name, new Date(date))}
                  renderInput={(params) => <ValidatableSuiInput fullWidth {...params} />}
                  disabled={!editing}
                  options={{
                    dateFormat: CONFIG_APP.BIRTH_DATE_FORMAT_DATEPICKER,
                    maxDate: "today",
                  }}
                  className="input"
                />
              ) :(
                <ValidatableSuiInput
                  fullWidth
                  name={field.name}
                  label={field.label}
                  type={field.type === 'checkbox' ? 'checkbox' : 'text'}
                  checked={field.type === 'checkbox' ? editedValues[field.name] || false : undefined}
                  value={editedValues[field.name]}
                  onChange={(e) => handleInputChange(field.name, field.type === 'checkbox' ? e.target.checked : e.target.value, field.type, field.isRequired)}
                  disabled={!editing}
                  required={field.isRequired}
                  inputProps={{ maxLength: field.type !== 'checkbox' ? MAX_LENGTH : undefined }}
                  className="input"
                  error={fieldErrors[field.name]}
                  errorMessage={fieldErrors[field.name]}
                />
              )
            ) : (
              <div className="text-value">
                {editedValues[field.name] !== null &&
                editedValues[field.name] !== undefined &&
                editedValues[field.name] !== '' ? (
                  field.type === 'date' ? (
                  editedValues[field.name] && !isNaN(new Date(editedValues[field.name])) ? (
                    <div className="text-value">
                      {format(
                        new Date(
                          typeof editedValues[field.name] === 'string' && editedValues[field.name].includes('/')
                            ? editedValues[field.name].split('/').reverse().join('-')
                            : editedValues[field.name]
                        ),
                        'dd/MM/yyyy'
                      )}
                    </div>
                  ) : (
                    <div className="text-value">{t('Invalid date')}</div>
                  )
                  ) : field.type === 'checkbox' ? (
                    editedValues[field.name] === true ? t('COMMON.yes') : t('COMMON.no')
                  ) : (
                  <div className="text-value">{editedValues[field.name]}</div>
                )
              ) : (
                <em>{t('No data')}</em>
              )}
              </div>
            )}
          </Grid>
        ))}
      </Grid>

      <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />

      <Grid container spacing={2} mt={1}>
        {addressFields.map((field, index) => (
          <Grid item xs={6} key={index} className="field-container">
            <div className={`label-text ${field.isEditable && editing ? 'editable' : ''}`}>
              {field.label}{(field.isRequired && editing) && <span className="required-field">*</span>}:
            </div>
            {field.type === 'dropdown' && editing ? (
              <SoftSelect
                value={{ value: editedValues[field.name], label: editedValues[field.name] }}
                onChange={(selectedOption) => handleInputChange(field.name, selectedOption)}
                options={STATES_OPTIONS}
                isDisabled={!field.isEditable || !editing}
                className="input"
              />
            ) : (
              field.isEditable && editing ? (
                <ValidatableSuiInput
                  fullWidth
                  name={field.name}
                  label={field.label}
                  value={editedValues[field.name]}
                  type={field.type}
                  onChange={(e) => handleInputChange(field.name, e.target.value, field.isRequired)}
                  disabled={!editing}
                  required={field.isRequired}
                  inputProps={{ maxLength: MAX_LENGTH }}
                  className="input"
                  error={fieldErrors[field.name]}
                  errorMessage={fieldErrors[field.name]}
                />
              ) : (
                <div className="text-value">
                  {editedValues[field.name] !== null &&
                    editedValues[field.name] !== undefined &&
                    editedValues[field.name] !== '' ? (
                    editedValues[field.name]
                  ) : (
                    <em>{t('No data')}</em>
                  )}
                </div>
              ))}
          </Grid>
      ))}
    </Grid>
    </div>
  );
});

CustomerTab.propTypes = {
  policyDetails: PropTypes.shape({
    bookingDataId: PropTypes.string,
    subscriber: PropTypes.shape({
      civility: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      birthDate: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      mobilePhone: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      country: PropTypes.string,
      preferredLanguage: PropTypes.string,
    }),
    beneficiaries: PropTypes.arrayOf(PropTypes.object),
    policy: PropTypes.shape({
      policyNumber: PropTypes.string,
      status: PropTypes.shape({
        label: PropTypes.string,
      }),
      genericData: PropTypes.shape({
        marketingOffers: PropTypes.string,
        state: PropTypes.string,
      }),
    }),
      insuranceData: PropTypes.shape({
        marketingOffers: PropTypes.bool,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
      }),
  }),
  onPolicyDetailsUpdate: PropTypes.func.isRequired,
};

export default CustomerTab;
