import React, {useContext, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import TimelineIcon from '@mui/icons-material/Timeline';
import NotesIcon from '@mui/icons-material/Notes';
import PaymentIcon from '@mui/icons-material/Payment';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import styles from './policyDetails.module.scss';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import SoftBox from "components/SoftBox";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Tab, Tabs} from '@mui/material';
import PolicyTab from './policyTab/policyTab';
import useFetchPolicyDetails from './useFetchPolicyDetails';
import CustomerTab from './customerTab/customerTab';
import SoftButton from 'components/SoftButton';
import PaymentTab from './paymentTab/paymentTab';
import EndorsementsTab from './endorsementsTab/endorsementsTab';
import useCancelPolicy from './useCancelPolicy';
import useSendMedicalCertificate from './useSendMedicalCertificate';
import useFetchCertificate from './useFetchCertificate';
import TravelersTab from './travelersTab/travelersTab';
import DialogConfirmation from 'shared/dialogs/DialogConfirmation';
import SoftTypography from 'components/SoftTypography';
import TimelineList from 'examples/Timeline/TimelineList';
import TimelineItem from 'examples/Timeline/TimelineItem';
import useFetchAuditHistory from './useFetchAuditHistory';
import SoftInput from 'components/SoftInput';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useAddNote from './useAddNote';
import {NotificationContext} from 'shared/context/notificationContext';
import SoftDatePicker from 'components/SoftDatePicker';
import useFetchPolicyRefund from './useFetchPolicyRefund';
import {CONFIG_APP} from "../../../shared/const/app.config";
import {AppConfigurationContext} from "../../../shared/context/configurationContext";
import useApi from "../../../shared/const/api-backend";
import useFetchEndorsements from "./endorsementsTab/useFetchEndorsements";
import {
  CancellationRejectCauses,
  checkIfFullRefundPossible,
  checkIfPartialRefundPossible,
  checkIfPolicyCanBeCancelled,
} from "./policyDetails.helper.js";
import useFetchEndorsementDetails from "./endorsementsTab/useFetchEndorsementDetails";
import SoftSelect from "../../../components/SoftSelect";
import { reportError } from 'shared/services/raygunService';
import useFetchMedicalCertificate from "./useFetchMedicalCertificate";

dayjs.extend(utc);

const PolicyDetails = () => {
  const { t } = useTranslation();
  const { policyId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const persistentQuoteId = urlParams.get("persistentQuoteId");
  const API = useApi();
  const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;
  const [dialogRefundType, setDialogRefundType] = useState(null);

  const { policyDetails, persistentQuoteDetails, fetchData } = useFetchPolicyDetails(
    policyId,
    persistentQuoteId
  );
  const { policyRefund, fetchRefundData, setPolicyRefund, refundRequestDate } = useFetchPolicyRefund();
  const { endorsements, fetchEndorsementsData } = useFetchEndorsements(policyId);
  const { fetchEndorsementDetails } = useFetchEndorsementDetails();
  const { cancelPolicy } = useCancelPolicy();
  const sendMedicalCertificate = useSendMedicalCertificate();
  const { fetchCertificateData } = useFetchCertificate();
  const { fetchMedicalCertificateData } = useFetchMedicalCertificate();
  const { auditHistory, fetchAuditHistory } = useFetchAuditHistory(policyId);
  const { appConfiguration, updateConfiguration } = useContext(
      AppConfigurationContext,
  );
  const { showNotification } = useContext(NotificationContext);
  const { addNote } = useAddNote();
  const navigate = useNavigate();

  const [state, setState] = useState({
    activeTab: 0,
    dialogs: {
      addNotePopup: false,
      showAuditHistoryPopup: false,
      showRefundPopup: false,
      showConfirmCancellation: false,
      showSendMedicalCertificate: false,
      showDownloadCertificate: false,
      showDownloadMedicalCertificate: false,
    }
  });

  const [note, setNote] = useState('');

  if (!policyDetails) {
    return null;
  }

  const getApplicationConfig = async () => {
    try {
      const configurationRes = await API.get(
          `${apiStrapiUrl}configuration`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_PORTAL_API_KEY}`,
            },
          },
      );
      updateConfiguration(configurationRes.data.data);
      return await configurationRes.data;
    } catch (error) {
      reportError(error);
    }
  };

  if (!appConfiguration) {
    getApplicationConfig();
  }

  const shouldShowSendMedicalCertificate = policyDetails?.beneficiaries?.some(beneficiary => beneficiary.wasScreened);

  const handleTabChange = (event, newValue) => {
    setState(prevState => ({ ...prevState, activeTab: newValue }));
  };

  const handleDialogToggle = (dialog) => {
    setState(prevState => ({
      ...prevState,
      dialogs: { ...prevState.dialogs, [dialog]: !prevState.dialogs[dialog] }
    }));
  };

  const handleDialogRefundToggle = (show, refundType) => {
    setDialogRefundType(refundType);
    setState(prevState => ({
      ...prevState,
      dialogs: { ...prevState.dialogs, showRefundPopup: show }
    }));
  }

  const handleConfirmPolicyCancellation = async () => {
    try {
      const result = await cancelPolicy(policyDetails, policyRefund, refundRequestDate);
      if (result) {
        const url = `/policies`;
        navigate(url);
      }
    } catch (error) {
      reportError(error);
    }
    handleDialogToggle('showConfirmCancellation');
    handleDialogToggle('showRefundPopup');
  };

  const handleSendMedicalCertificate = async () => {
    try {
      const { success, error } = await sendMedicalCertificate(policyDetails?.bookingDataId);

      if (success) {
        // console.log('Medical certificate sent successfully.');
      } else {
        // console.error('Error sending medical certificate:', error);
      }
    } catch (error) {
      reportError(error);
    }
    handleDialogToggle('showSendMedicalCertificate');
  };

  const getAuditLogIcon = (action) => {
    if (action.includes('CUSTOMER')) {
      return 'person'
    }
    if (action.includes('POLICY')) {
      return 'policy'
    }
    if (action.includes('MEDICAL')) {
      return 'medical_services'
    }
    if (action.includes('NOTE')) {
      return 'notes'
    }
    return 'adjust'
  }

  const handleDownloadMedicalCertificate = async () => {
    await fetchMedicalCertificateData(policyDetails?.bookingDataId).then((medCertResponse) => {
      const base64Data = medCertResponse.file;
      const filename = medCertResponse.name;
      const blobData = base64ToBlob(base64Data);
      const blob = new Blob([blobData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }).finally(() => handleDialogToggle('showDownloadMedicalCertificate'))
  };

  const handleDownloadCertificate = async () => {
    await fetchCertificateData(policyDetails?.policy.policyNumber, policyDetails?.product.iac).then((certificateData) => {
      const base64Data = certificateData.file;
      const filename = certificateData.name;
      const blobData = base64ToBlob(base64Data);
      const blob = new Blob([blobData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }).finally(() => handleDialogToggle('showDownloadCertificate'))
  };

  function base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i += 1) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const handlePolicyDetailsUpdate = () => {
    fetchData(policyId);
  };

  const openAuditHistory = async () => {
    await fetchAuditHistory(policyDetails.bookingDataId);
    handleDialogToggle('showAuditHistoryPopup')
  }

  const addNoteHandler = () => {
    addNote(policyDetails?.bookingDataId, note);
    handleDialogToggle('addNotePopup');
    setNote('');
  }

  const refundHandler = () => {
    // if (policyDetails.policy.price.grossPremium < policyRefund) {
    //   showNotification(t('POLICY_DETAILS.cancellationOfPolicyRefundAmountError'), 'error');
    //   return;
    // }
    // if (policyDetails.policy.price.grossPremium > policyRefund &&
    //   (dayjs(refundDate).isBefore(dayjs(policyDetails.insuranceData.bookingDate).add(14, 'day')) ||
    //     dayjs(refundDate).isAfter(dayjs(policyDetails.insuranceData.endDate)))) {
    //   showNotification(t('POLICY_DETAILS.cancellationOfPolicyDateError'), 'error');
    //   return;
    // }
    // if (policyDetails.policy.price.grossPremium === policyRefund && (dayjs(refundDate).isBefore(dayjs(policyDetails.insuranceData.bookingDate)) || dayjs(refundDate).isAfter(dayjs(policyDetails.insuranceData.startDate).subtract(14, 'day')))) {
    //   showNotification(t('POLICY_DETAILS.cancellationOfPolicyFullRefundError'), 'error');
    //   return;
    // }
    handleDialogToggle('showConfirmCancellation');
  }

  const getEndorsementsTripDurationDetails = async (endorsement) => {
    return fetchEndorsementDetails(
        endorsement.endorsementNumber, policyDetails.product.iac, true);
  }

  const handleRefundNewPolicyCreated = (value) => {
      fetchRefundData(policyDetails, refundRequestDate, value);
  }

  const refundRequestDateHandler = (value) => {
    if (checkIfFullRefundPossible(policyDetails, endorsements)) {
        setDialogRefundType('FULL_REFUND');
        fetchRefundData(policyDetails, value);
    }
    if (checkIfPartialRefundPossible(policyDetails, endorsements)) {
        setDialogRefundType('PRO_RATA_REFUND');
        fetchRefundData(policyDetails, value);
    }
    console.error('ERROR REFUND REQUEST DATE HANDLER');
  }


  const cancelPolicyButtonHandler = async () => {
    const cantBeCancelled = checkIfPolicyCanBeCancelled(policyDetails, endorsements);
    // CHECK IF POLICY CAN BE CANCELLED
    if (cantBeCancelled) {
      if (cantBeCancelled === CancellationRejectCauses.POLICY_ALREADY_ENDED) {
        showNotification(t('POLICY_DETAILS.daysPolicyCancellationLimit'), 'error');
      } else if (cantBeCancelled === CancellationRejectCauses.POLICY_CONTAINS_ENDORSEMENTS) {
        showNotification(t('POLICY_DETAILS.cancellationOfPoliciesWithEndorsements'), 'error');
      }
      return;
    }

    // FULL REFUND
    if (checkIfFullRefundPossible(policyDetails, endorsements)) {
      handleDialogRefundToggle(true, 'FULL_REFUND');
      fetchRefundData(policyDetails, dayjs().toDate());
      return;
    }

    // PRO RATA REFUND
    if (checkIfPartialRefundPossible(policyDetails, endorsements)) {
      handleDialogRefundToggle(true, 'PRO_RATA_REFUND');
      fetchRefundData(policyDetails, dayjs().toDate());
    }

  };

  const fetchEndorsementsDataHandler = () => {
    fetchEndorsementsData();
    // fetchEndorsementsData(policyDetails.policy.policyNumber);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.PolicyDetails} data-testid="PolicyDetails">
        <Tabs value={state.activeTab} onChange={handleTabChange} className={styles.tabs}>
          <Tab label={t('POLICY_DETAILS.tabPolicy')} style={{ flexGrow: 0, marginRight: '1rem', padding: "0 1rem" }} />
          <Tab label={t('POLICY_DETAILS.tabCustomer')} style={{ flexGrow: 0, marginRight: '1rem', padding: "0 1rem" }} />
          <Tab label={t('POLICY_DETAILS.tabTravelers')} style={{ flexGrow: 0, marginRight: '1rem', padding: "0 1rem" }} />
          <Tab label={t('POLICY_DETAILS.tabPayment')} style={{ flexGrow: 0, marginRight: '1rem', padding: "0 1rem" }} />
          <Tab label={t('POLICY_DETAILS.tabEndorsements')} style={{ flexGrow: 0, padding: "0 1rem" }} />
        </Tabs>

        <SoftBox bgColor="white" borderRadius="lg" shadow="lg" p={2} mb={2}>
          <Grid container spacing={2} className={styles.policyInfo}>
            <Grid item xs={6}>
              <span className={styles.policyNumber}>
                <span style={{marginRight: '5px'}}>
                <b style={{marginRight: '2px'}} >{t('POLICY_DETAILS.policyNumber')}:</b>
                  {policyDetails.policy.policyNumber}
                </span>
                  {policyDetails.legacyPolicyId &&
                      <span>({t('POLICY_DETAILS.legacyPolicyId')}: {policyDetails.legacyPolicyId})</span>
                  }
              </span>
            </Grid>
            <Grid item xs={6}>
              <b>{t('POLICY_DETAILS.status')}:</b>
              <span style={{ textTransform: 'uppercase', color: policyDetails.policy.status.label === 'cancelled' ? 'red' : (policyDetails.policy.status.label === 'validated' ? 'green' : 'initial') }}> {policyDetails.policy.status.label}</span>
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox bgColor="white" borderRadius="lg" shadow="lg" p={2}>
          {state.activeTab === 0 && <PolicyTab policyDetails={policyDetails} persistentQuoteDetails={persistentQuoteDetails} onPolicyDetailsUpdate={handlePolicyDetailsUpdate} />}
          {state.activeTab === 1 && <CustomerTab policyDetails={policyDetails} onPolicyDetailsUpdate={handlePolicyDetailsUpdate} />}
          {state.activeTab === 2 && <TravelersTab policyDetails={policyDetails} onPolicyDetailsUpdate={handlePolicyDetailsUpdate} />}
          {state.activeTab === 3 && <PaymentTab policyDetails={policyDetails} />}
          {state.activeTab === 4 && <EndorsementsTab policyDetails={policyDetails} endorsements={endorsements} fetchData={fetchEndorsementsDataHandler} />}
        </SoftBox>

        <div className={styles.buttonsContainer}>
          {policyDetails.policy.status.label !== 'cancelled' && (
            <SoftButton variant="contained" color="error" onClick={() => cancelPolicyButtonHandler()}>
              {t('POLICY_DETAILS.cancelPolicy')}
            </SoftButton>
          )}
          {shouldShowSendMedicalCertificate && (
            <SoftButton variant="contained" color="secondary" onClick={() => handleDialogToggle('showSendMedicalCertificate')}>
              {t('POLICY_DETAILS.sendMedicalCertificate')}
            </SoftButton>
          )}
          {shouldShowSendMedicalCertificate && (
            <SoftButton variant="contained" color="secondary" onClick={() => handleDialogToggle("showDownloadMedicalCertificate")}>
              {t('POLICY_DETAILS.downloadMedicalCertificate')}
            </SoftButton>
          )}
          <SoftButton variant="contained" color="secondary" onClick={() => handleDialogToggle('showDownloadCertificate')}>
            {t('POLICY_DETAILS.downloadPolicyCertificate')}
          </SoftButton>

          <div className={styles.additionalButtons}>
            <SoftButton variant="outlined" color="secondary" onClick={() => openAuditHistory()}>
              {t('POLICY_DETAILS.auditHistory')}
            </SoftButton>
            <SoftButton variant="outlined" color="secondary" onClick={() => handleDialogToggle('addNotePopup')}>
              {t('POLICY_DETAILS.addNote')}
            </SoftButton>
          </div>
        </div>
      </div>

      <Dialog className="audit-log-dialog"
        PaperProps={{ style: { maxWidth: '600px' } }}
        fullWidth
        open={state.dialogs.showAuditHistoryPopup}>
        {state.dialogs.showAuditHistoryPopup && <>
          <DialogTitle>
            <SoftTypography>
              <TimelineIcon sx={{ paddingTop: '0.3rem' }} /> {t('POLICY_DETAILS.AUDIT_HISTORY.title')}
            </SoftTypography>
          </DialogTitle>
          <SoftBox>
            <DialogContent sx={{ paddingTop: 0, maxHeight: '60vh' }}>
              <TimelineList>
                {auditHistory.map((h) => (
                  <TimelineItem
                    key={h.id}
                    color="info"
                    icon={getAuditLogIcon(h.action)}
                    title={t(`POLICY_DETAILS.AUDIT_HISTORY.${h.action}`)}
                    dateTime={dayjs(h.createdDate).local().format('MM/DD/YYYY HH:mm')}
                    subtitle={`${t(`POLICY_DETAILS.AUDIT_HISTORY.${h.userType}`)}${h.username && ` (${h.username})`}`}
                    description={h.message}
                  />
                )
                )}
              </TimelineList>
            </DialogContent>
            <DialogActions>
              <SoftButton size='small' onClick={() => handleDialogToggle('showAuditHistoryPopup')}>
                <CloseIcon sx={{ marginRight: '0.2rem' }} />
                {t('COMMON.close')}
              </SoftButton>
            </DialogActions>
          </SoftBox>
        </>}
      </Dialog>
      <Dialog open={state.dialogs.addNotePopup} fullWidth>
        <DialogTitle>
          <SoftTypography>
            <NotesIcon sx={{ paddingTop: '0.3rem' }} /> {t('POLICY_DETAILS.NOTES.title')}
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <SoftInput
              autoFocus
              label={t('POLICY_DETAILS.NOTES.placeholder')}
              placeholder={t('POLICY_DETAILS.NOTES.placeholder')}
              multiline
              rows={4}
              onChange={(e) => setNote(e.target.value)}
              value={note}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <SoftButton size='small' onClick={() => { handleDialogToggle('addNotePopup'); setNote('') }}>
            <CloseIcon sx={{ marginRight: '0.2rem' }} />
            {t('COMMON.cancel')}
          </SoftButton>
          <SoftButton size='small' type="submit" color="success" disabled={!note} onClick={addNoteHandler}>
            <CheckIcon sx={{ marginRight: '0.2rem' }} />
            {t('COMMON.save')}
          </SoftButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={state.dialogs.showRefundPopup}
        onClose={() => { handleDialogToggle('showRefundPopup'); setPolicyRefund(undefined) }}
        fullWidth>
        <DialogTitle>
          <SoftTypography>
            <PaymentIcon sx={{ paddingTop: '0.3rem' }} /> {t('POLICY_DETAILS.REFUND.title')}
          </SoftTypography>
        </DialogTitle>
        <DialogContent>


          <SoftBox mb={2} mt={2}>
            { dialogRefundType === 'PRO_RATA_REFUND' &&
                <FormControl>
                  <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                      mb={0.5}
                  >
                    {t('POLICY_DETAILS.REFUND.newPolicyCreated')}
                  </SoftTypography>
                  <SoftSelect
                      input={{
                        placeholder: t(`POLICY_DETAILS.REFUND.newPolicyCreated`),
                      }}
                      defaultValue={{ value: true, label: t(`COMMON.no`) }}
                      options={[
                        { value: true, label: t(`COMMON.yes`)},
                        { value: false, label: t(`COMMON.no`) },
                      ]}
                      onChange={(event) => {
                        handleRefundNewPolicyCreated(event.value);
                      }}
                  />
                </FormControl>
            }
            <FormControl fullWidth sx={{marginTop: "0.3rem"}}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
                mb={0.5}
              >
                {t('POLICY_DETAILS.REFUND.requestDate')}
              </SoftTypography>
              <SoftDatePicker
                input={{
                  required: true,
                  placeholder: t(`POLICY_DETAILS.REFUND.requestDate`),
                }}
                options={{
                  required: true,
                  maxDate: "today",
                  minDate: policyDetails.insuranceData.bookingDate,
                  defaultDate: "today",
                  dateFormat: CONFIG_APP.DATE_FORMAT_DATEPICKER,
                  parseDate: function (dateStr, format) {
                    return dayjs(dateStr, { format }).toDate();
                  },
                  formatDate: function (date, format) {
                    return dayjs(date).format(format);
                  },
                }}
                onChange={(event) => {
                  refundRequestDateHandler(event[0])
                }}
              />
            </FormControl>
            {
              policyRefund !== null && policyRefund !== undefined &&
              <FormControl fullWidth>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('POLICY_DETAILS.refundAmount')}
                  </SoftTypography>
                  <SoftInput
                    placeholder={t('POLICY_DETAILS.refundAmount')}
                    value={policyRefund}
                    defaultValue={policyRefund}
                    onChange={(v) => {
                      const value = v.target.value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1').slice(0, 14);
                      setPolicyRefund(value)
                    }}
                    error={+policyRefund > +policyDetails.policy.price.grossPremium}
                  />
                  {+policyRefund > +policyDetails.policy.price.grossPremium &&
                    <SoftTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                      sx={{ color: 'red', position: 'absolute', marginTop: '5px' }}
                    >
                      {t('POLICY_DETAILS.cancellationOfPolicyRefundAmountError')}
                    </SoftTypography>
                  }
                </SoftBox>
              </FormControl>
            }
          </SoftBox>
        </DialogContent>
        <DialogActions>
          <SoftButton size='small' onClick={() => { handleDialogToggle('showRefundPopup'); setPolicyRefund(undefined) }}>
            <CloseIcon sx={{ marginRight: '0.2rem' }} />
            {t('COMMON.cancel')}
          </SoftButton>
          <SoftButton size='small' type="submit" color="success" disabled={!policyRefund || +policyRefund > +policyDetails.policy.price.grossPremium} onClick={refundHandler}>
            <CheckIcon sx={{ marginRight: '0.2rem' }} />
            {t('COMMON.confirm')}
          </SoftButton>
        </DialogActions>
      </Dialog>
      <DialogConfirmation open={state.dialogs.showConfirmCancellation} onClose={() => handleDialogToggle('showConfirmCancellation')} onAction={handleConfirmPolicyCancellation} actionColor="error" actionLabel="confirm" path="POLICY_DETAILS.POLICY_CANCELLATION" />
      <DialogConfirmation open={state.dialogs.showSendMedicalCertificate} onClose={() => handleDialogToggle('showSendMedicalCertificate')} onAction={handleSendMedicalCertificate} actionColor="success" actionLabel="confirm" path="POLICY_DETAILS.MEDICAL_CERTIFICATE" />
      <DialogConfirmation open={state.dialogs.showDownloadCertificate} onClose={() => handleDialogToggle('showDownloadCertificate')} onAction={handleDownloadCertificate} actionColor="success" actionLabel="confirm" path="POLICY_DETAILS.DOWNLOAD_POLICY" />
      <DialogConfirmation
        open={state.dialogs.showDownloadMedicalCertificate}
        onClose={() => handleDialogToggle("showDownloadMedicalCertificate")}
        onAction={handleDownloadMedicalCertificate}
        actionColor="success"
        actionLabel="confirm"
        path="POLICY_DETAILS.DOWNLOAD_MEDICAL_CERTIFICATE"
      />
    </DashboardLayout>
  );
};

export default PolicyDetails;
