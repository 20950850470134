import { format } from 'date-fns';

/**
 * Transforms edited form values into the structure expected by the API
 * @param {Object} policyDetails - The original policy details
 * @param {Object} editedValues - The edited form values
 * @param {Array} editedTravelers - The edited travelers/beneficiaries
 * @returns {Object} - The transformed policy data ready for API submission
 */
export const transformPolicyData = (policyDetails, editedValues, editedTravelers) => {
  return {
    ...policyDetails,
    insuranceData: {
      ...policyDetails.insuranceData,
      startDate: format(new Date(editedValues.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(editedValues.endDate), 'yyyy-MM-dd'),
    },
    subscriber: {
      ...policyDetails.subscriber,
      civility: editedValues.civility,
      firstName: editedValues.firstName,
      lastName: editedValues.lastName,
      birthDate: editedValues.birthDate ? format(new Date(editedValues.birthDate), 'yyyy-MM-dd') : null,
      email: editedValues.email,
      phone: editedValues.phone,
      mobilePhone: editedValues.mobilePhone,
      address1: editedValues.address1,
      address2: editedValues.address2,
      city: editedValues.city,
      postalCode: editedValues.postalCode,
      country: editedValues.country,
      preferredLanguage: editedValues.preferredLanguage,
    },
    beneficiaries: editedTravelers.map(traveler => {
      if (traveler.subscriber) {
        return {
          ...traveler,
          firstName: editedValues.firstName,
          lastName: editedValues.lastName,
          birthDate: editedValues.birthDate ? format(new Date(editedValues.birthDate), 'yyyy-MM-dd') : null,
        };
      }
      return {
        ...traveler,
        birthDate: traveler.birthDate ? format(new Date(traveler.birthDate), 'yyyy-MM-dd') : null,
      };
    }),
    policy: {
      ...policyDetails.policy,
      genericData: {
        ...policyDetails.policy.genericData,
        state: editedValues.state,
      },
    },
  };
}; 