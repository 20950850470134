import { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import useApi from "../../../shared/const/api-backend";
import {CONFIG_TABLE} from "../../../shared/const/app.config";
import { UserDataContext } from "shared/context/userDataContext";
import { reportError } from "shared/services/raygunService";

const useFetchPoliciesData = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const API = useApi();
  const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

  const fetchData = async (filters = {}) => {
    setLoading(true);

    if (dataCenter?.cm360Endpoint) {
      try {
        const params = {
          offset: 0,
          limit: CONFIG_TABLE.MAX_ROWS_FROM_BACKEND,
        };

        Object.entries(filters).forEach(([key, value]) => {
          if (value && value.trim() !== "") {
            params[`filter[${key}]`] = value.trim();
          }
        });

        const response = await API.get(`${apiStrapiUrl}policies`, {
          params,
        });

        setData(response.data);
      } catch (error) {
        showNotification("COMMON.error.fetchingData", "error");
        reportError(error);
      } finally {
        setLoading(false);
      }
    }
  };


  useEffect(() => {
    if (!dataCenter || dataCenter.length === 0) {
      showNotification("COMMON.error.noDataCenterSelected", "error");
      return;
    };
    fetchData();
  }, [dataCenter]);

  return { data, error, fetchData };
};

export default useFetchPoliciesData;
