import React from 'react';
import PropTypes from 'prop-types';
import SoftSelect from 'components/SoftSelect';

const PolicyFormSelect = ({ name, label, value, onChange, options, error, errorMessage }) => {
  return (
    <SoftSelect
      value={{ value: value, label: value }}
      onChange={(e) => onChange(e.value)}
      options={options}
      className="input"
      error={error}
      errorMessage={errorMessage}
    />
  );
};

PolicyFormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default PolicyFormSelect; 