import React, { useState } from 'react';
import styles from './policies.module.scss';
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { Box, Button } from '@mui/material';
import {useTranslation} from "react-i18next";
import useFetchPoliciesData from './useFetchPoliciesData';
import SuiInput from "../../../components/SoftInput";
import PoliciesTable from './policies-table.js/policies-table';
import SoftTypography from 'components/SoftTypography';
import SoftButton from "../../../components/SoftButton";
import PoliciesSearch from "./policies-search/policies-search";

const Policies = () => {
  const {t} = useTranslation();
  const { data, fetchData } = useFetchPoliciesData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.Policies} data-testid="Policies">
        <Box className="table-toolbar" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
          <SoftTypography>{t('POLICIES.header')}</SoftTypography>
        </Box>
        <div className={`${styles['search-container']}`}>
          <PoliciesSearch fetchData={fetchData} />
        </div>
        <PoliciesTable data={data}></PoliciesTable>
      </div>
    </DashboardLayout>
  );
};

Policies.propTypes = {};

Policies.defaultProps = {};

export default Policies;
